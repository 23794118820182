.dashboard-page {
  .card-icon {
    width: 50px;
    height: 50px;
    background-color: aliceblue;
    text-align: center;
    font-size: 20px;
    line-height: 50px;
    color: #222;
    border-radius: 50%;
  }
}

@media (max-width: 767.98px) {
  .user-profile-wrapper {
    display: flex;
    flex-direction: column;
    .inner-wrapper {
      display: flex;
      flex-direction: column;
    }
  }
}
