.slider-image{
    .slider-image-list{
       box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
       border-radius: 4px;
       background-color: #F8F9FD;
       position: relative;
       
       .slide_search {
        width: 100%;
        &.ant-select {
          &.ant-select-auto-complete {
            &.ant-select-show-search {
              width: 100%;
            }
          }
        }
      }
       .slide-image{
        width: 100%;
        height: 20vh;
        position: relative;
        overflow: hidden;
        z-index: 0;

            img{
            object-fit: cover;    
            min-height: 100%;
            min-width: 100%;
            overflow: hidden;
            }
    }
        .slide_title{
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        .slide-name{
            height: 70px;
            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
            color: #3A494E;
             padding: 0 10px;

      .slide-icon {
        background-color: #dfdfdf;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin: 0 5px;
      }
    }
  }
}
.slider_status{
    position: absolute;
    right: 0;
    top: 25px;
    padding: 5px 10px;
    color: #fff;
    text-align: center;
    font-weight: $bold;
    .status_top_part{
        position: relative;
       /* &:before{
            content: '';
            display: block;
            width: 30px;
            height: 30px;
            position: absolute;
            top:  0;
            right: 0;
            transform:translateY(-17px);
            z-index: -1000;
            background-color: #34A852;
        }*/
  }
  .status_traingle_part {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid black;
    //transform:translateY(-17px);
  }
}
.bg_active_slide {
  background-color: #34a852;
  .status_triangle_part {
    width: 0;
    height: 0;
    border-left: 1px solid #34a852;
    border-right: 18px solid transparent;
    border-bottom: 18px solid #34a852;
    position: absolute;
    top: -18px;
    right: 0px;
  }
}
.bg_inactive_slide {
  background-color: #e73d42;
  .status_triangle_part {
    width: 0;
    height: 0;
    border-left: 1px solid #e73d42;
    border-right: 18px solid transparent;
    border-bottom: 18px solid #e73d42;
    position: absolute;
    top: -18px;
    right: 0px;
  }
}
.slide-drawer-wrapper {
  padding: 0 5px;
}
.slide-drawer-wrapper{
    padding: 0 5px;
}
.slider_status_create_box{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 15px 0;
}

.create_slide_btn{
    border: 1px solid #34a852 !important;
  border-radius: 4px;
  span {
    display: inline-block;
    margin-left: 6px;
    color: #34a852;
  }
}
@media only screen and (max-width: 767px) {
   
    .slider_status_create_box{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .create_slide_btn{
        width: 100%;
    }
    .mob_mr_top_15{
        margin-top: 15px;
    }
}
@media only screen and (max-width: 991px) {
    .mob_mr_left_right{
        margin: 0 15px;
    }
}
