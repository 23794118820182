.edit-form-area {
  .product-form-item {
    .ant-form-item-label {
      display: block !important;
      width: 100% !important;
      text-align: left !important;
    }
  }
}
.edit-product-modal-area {
  .modal-dialog {
    &.modal-lg {
      max-width: 1000px;
    }
  }
}
