.role-management-dashboard-area {
  max-width: 1200px;
  height: auto;
  width: 1200px;
  position: relative;
  .role-management-items {
    height: 100%;
    width: 100%;
    padding-top: 80px;
    padding-bottom: 80px;
    margin-right: 0 !important;
    margin-left: 0 !important;
    .single-card-item {
      display: block;
      background: #ffffff;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      padding: 15px 20px;
      transition: all .3s linear;
      margin-bottom: 20px;
      overflow: hidden;
      .role-icon {
        height: 46px;
        width: 46px;
        border-radius: 50%;
        background: #e62d26;
        text-align: end;
        line-height: 46px;
        margin-bottom: 12px;
        svg {
          text-align: center;
          width: inherit !important;
          height: 22px !important;
          line-height: 46px !important;
          transition: all .5s linear;
          path {
            fill: #fff;
          }
        }
      }
      h3 {
        line-height: 17.5px;
        width: auto;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        a {
          font-size: 13px;
          font-weight: 700;
          text-transform: uppercase;
          color: #000;
          line-height: 20.5px;
          min-height: 38px !important;
        }
      }
      p {
        color: #777171;
        font-size: 12px;
        font-weight: 400;

      }
      &:hover {
        box-shadow: 1px 3px 10px 3px rgba(21, 20, 20, .25);
        .role-icon {
          svg {
            transform: rotate(-360deg);
          }
        }
      }
    }
  }
}