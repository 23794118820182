.aside-menu {
//   height: 20rem;
  overflow-y: auto;
  .ant-menu {
    background: transparent !important;

    .ant-menu-item {
      padding-left: 20px !important;
      a {
        font-size: 13px;
        font-weight: 700;
        color: #3b4d68 !important;
        position: relative;
        display: block;
        svg {
          margin-right: 20px;
        }
      }
      &.active {
        background-color: #feeeed;
        position: relative;
        color: $theme-color;
        &::before {
          top: 0;
          content: "";
          position: absolute;
          left: 0;
          height: 100%;
          border-left: 4px;
          width: 4px;
          background-color: $theme-color;
        }
      }
    }
  }
}
