.snake {
    position: relative;
    -webkit-box-reflect: below -12px linear-gradient(transparent, rgba(0,0,0,0.2));
    span {
        position: relative;
        display: inline-block;
        color: #3a3a3a;
        font-size: 16px;
        &.first {
            animation:animate1 1s ease-in-out infinite;
        }
        &.second {
            animation:animate2 1.3s ease-in-out infinite;
        }
    }

}
@keyframes animate1 {
    0% {
        transform: translateY(0px);
    }
    20% {
        transform: translateY(-20px);
    }
    40%, 100% {
        transform: translateY(0px);
    }
}
@keyframes animate2 {
    0% {
        transform: translateY(0px);
    }
    20% {
        transform: translateY(-20px);
    }
    40%, 100% {
        transform: translateY(0px);
    }
}
