
.first {
  // height: 100%;
  // background-image: url("../../images/background/gift.png");
  background-size: cover;
  background-position: center;
  overflow: hidden;
}
.become {
  color: red;
  font-size: 35px;
  font: Uni Neue;
  font-style: normal;
  font-weight: 500;
  @include mobile-portrait-max {
    font-size: 23px;
  }
}
.seller {
  color: black;
  font-size: 35px;
  font: Uni Neue;
  font-style: normal;
  font-weight:300;
  line-height: .5;
  @include mobile-portrait-max {
    font-size: 22px;
    //margin-top: -5%;
  }
}
.just {
  margin-top: 0%;
  color: rgb(80, 80, 80);
  font-size: 16px;
  font: Uni Neue;
  font-style: normal;
  font-weight: 300;
  @include mobile-portrait-max {
    font-size: 12px;
  }
}
.get {
  margin-top: 18px;
  color: rgb(80, 80, 80);
  font-size: 16px;
  font: Uni Neue;
  font-style: normal;
  font-weight: 300;
  @include mobile-portrait-max {
    font-size: 12px;
    //margin-top: -1%;
  }
}
.start {
  height: 20%;
  width: 50%;
  background-color: transparent;
  margin-left: 147px;
  margin-top: 230px;
}
.sfont {
  color: rgb(80, 80, 80);
  font-size: 57px;
  font: Uni Neue;
  font-style: normal;
  font-weight: 300;
}
.put {
  width:100%;
  margin-top: 0%;
  margin-left: 1%;
  color: rgb(80, 80, 80);
  font-size: 20px;
  font: Uni Neue;
  font-style: normal;
  font-weight: 400;
}
.customer {
  margin-top: 0%;
  margin-left: 1%;
  color: rgb(80, 80, 80);
  font-size: 20px;
  font: Uni Neue;
  font-style: normal;
  font-weight: 400;
}

.luser,
.lunlock {
  height: 27px;
  max-width: 330px;
  margin: 35px auto 0px auto;
  background-color: white;
}

.luser.error {
  margin-top: 5px;
  height: auto;
  width: 100%;
  text-align: center;
}

.df {
  height: 40px;
  max-width: 310px;
  //margin-left: 135px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  background-color: white;
  //padding:10px 10px 0px 10px;
  @include mobile-portrait-max {
    max-width: 295px;
    //margin-left:0px;
  }
}
.forgot {
  margin-top: 9px;
  color: black;
  font-size: 14px;
  font: Uni Neue;
  font-style: normal;
  font-weight: 300px;
  //margin-left:20px;
  line-height: 24px;
  float: left;

  @include mobile-portrait-max {
    font-size: 12px;
    padding: 5px;
    //margin-left:43px;
  }
}

.check {
  margin-left: 21px;

  margin-top: 13px;
  @include mobile-portrait-max {
    margin-left: 5px;
    //margin-left:38px;
  }
}
.remember {
  color: black;
  font-size: 14px;
  font: Uni Neue;
  font-style: normal;
  font-weight: 300px;
  margin-top: 9px;
  margin-left: 7px;
  //float: right;
  //margin:9px auto 0px auto;
  @include mobile-portrait-max {
    font-size: 12px;
    margin-left: 7px;
  }
}

.button {
  background-color: $theme-color;
  color: white;
  font-size: 14px;
  height: 44px;
  width: 300px;
  display: block;
  margin: 13px auto auto;
  border-radius: 5px;
  border: none;

  @include mobile-portrait-max {
    width: 200px;
    //margin-left: 48px;
    margin-top: 8px;

    //margin-right: 48px;
  }
}
.bt {
  @include mobile-portrait-max {
    //width:220px;
    align-items: center;
    margin-top: 8px;
    //padding: 0px 7px 0px 7px;
    padding-left: 5px;
    //padding-right: 5px;
  }
}
.create {
  height: 40px;
  background-color: white;

  .fcreate {
    color: "black";
    font-size: 14px;
    font: "Uni Neue";
    font-style: "normal";
    font-weight: 350px;
    text-align: center;
    @include mobile-portrait-max {
      font-size: 12px;
    }
  }
}
.small {
  display: flex;
  width:100%;
  @include mobile-portrait-max {
    align-items: center;
    padding: 5px;
    display: flex;
  }
}
.pstyle {
  font-size: 14px;
  width: 100%;
  margin-top: -2%;
  @include mobile-portrait-max {
    font-size: 12px;
  }
}
.ustyle {
  font-size: 14px;
  margin-top: -4%;
  width: 100%;
  margin-left: 9px;
  @include mobile-portrait-max {
    font-size: 12px;
  }
}
.u2style {
  font-size: 14px;
  margin-top: -3%;
  width: 100%;
  margin-left: 9px;
  @include mobile-portrait-max {
    font-size: 12px;
    margin-top: -3%;
  }
}

.newclass{
  height:100%;
}
.marginforp{
  @include mobile-portrait-max {
  margin-bottom: 10px;
  }
}
.rememberstyle{
  width:100%;
  display: flex;
  justify-content:space-evenly;
  
}