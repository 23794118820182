.ant-layout-has-sider {
  .ant-layout-sider {
    background: #fff;
    .ant-layout-sider-children {
      .sidebar-menu {
        .dashboard-logo {
          box-shadow: 0 4px 4px rgba(231, 231, 231, .25);
          background: #fff;
          line-height: 63px;
          padding: 7.5px 16px;
          /* display: flex; */
          justify-content: space-between;
          align-items: center;
          height: 70px;
        }
        .author-logo {
          display: block;
          text-align: center;
          height: 40px;
          width: 40px;
          border-radius: 50%;
          margin-left: 22px;
          margin-top: 30px;
          img {
            border-radius: 50%;
          }
        }
        .inner-sidebar-content {
          padding-top: 50px;
          .ant-menu-submenu {
            .ant-menu-submenu-title {
              transition: all 0.3s linear;
              span {
                transition: all 0.3s linear;
              }
            }
            &:hover {
              .ant-menu-submenu-title {
                background: #feeeed;
                color: #e62d26;
                span {
                  color: #e62d26;
                }
              }
            }
            &.active {
              .ant-menu-submenu-title {
                background: #feeeed;
                color: #e62d26;
                span {
                  color: #e62d26;
                }
              }
            }
          }

          .ant-menu-item {
            transition: all 0.3s linear;
            &.active {
              background: #feeeed;
              color: #e62d26;
              a {
                color: #e62d26;
                font-weight: 700;
              }
              &::before {
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                width: 4px;
                height: 100%;
                background-color: #e62d26;
              }
            }
            span {
              a {
                transition: all 0.3s linear;
                &:hover {
                  color: #e62d26;
                }
              }
              transition: all 0.3s linear;
              &:hover {
                color: #e62d26;
              }
            }
          }
        }
      }
    }
    .ant-layout-sider-trigger {
      position: absolute;
      top: 6px;
      background: transparent;
      left: 184px !important;
      width: 30px !important;
      span {
        height: 20px;
        width: 20px;
        background: red;
        border-radius: 4px;
        line-height: 18px;
        position: relative;
        svg {
          path {
          }
        }
        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          height: 30px;
          width: 30px;
          background: url('../../images/thumbs.png');
          background-repeat: no-repeat;
          background-size: cover;
        }
      }
    }
  }
  .ant-layout {
    overflow-x: hidden;
    .top-header-area {
    }
    .main-content-layout {
    }
  }
}
