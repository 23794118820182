.ant-steps {
  .ant-steps-item {
    .ant-steps-item-container {
      &:hover {
        .ant-steps-item-icon {
          border-color: #dc3545 !important;
          .ant-steps-icon {
            color: #dc3545 !important;
          }
        }
        .ant-steps-item-content {
          .ant-steps-item-title {
            color: #dc3545 !important;
          }
        }
      }
    }

    &.ant-steps-item-active,
    .ant-steps-item-process {
      .ant-steps-item-icon {
        background: #dc3545 !important;
        border-color: #dc3545 !important;
        .ant-steps-icon {
          color: white !important;
        }
      }
      .ant-steps-item-container {
        &:hover {
          .ant-steps-item-icon {
            .ant-steps-icon {
              color: white !important;
            }
          }
        }
      }
    }

    &.ant-steps-item-finish {
      .ant-steps-item-icon {
        background: white !important;
        border-color: #dc3545 !important;
        > .ant-steps-icon {
          color: #dc3545;
        }
      }
      .ant-steps-item-title::after {
        background-color: #dc3545 !important;
      }
    }
  }
}

.steps-content {
  margin-top: 30px;

  .ant-form h4:first-child {
    margin-top: 0 !important;
  }
}

.steps-action {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .ant-btn.ant-btn-primary {
    color: #fff !important;
    border-color: #dc3545 !important;
    background: #dc3545 !important;
    svg {
      color: #fff;
    }
    &:hover,
    &:active,
    &:focus {
      color: #fff !important;
      border-color: #dc3545 !important;
      svg {
        color: white;
      }
    }
  }
  .ant-btn {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      color: #d9d9d9;
    }
    &:hover,
    &:active,
    &:focus {
      color: #dc3545 !important;
      border-color: #dc3545 !important;
      svg {
        color: #dc3545;
      }
    }
  }
}

.order-status-description-area {
  .single-order-status-description {
    margin-bottom: 31px;
    .order-date {
      span {
        font-size: 12px;
        font-weight: 700;
        color: #777171;
        display: inline-block;
      }
    }
    .description {
      h4 {
        font-size: 12px;
        font-weight: 700;
        line-height: 20px;
      }
      p {
        font-size: 12px;
        color: #777171;
        margin-bottom: 0;
      }
    }
    .payment-status {
      span {
        display: inline-block;
        padding: 5px 10px;
        background: #3a494e;
        border-radius: 2px;
        color: #fff;
        font-weight: 700;
        font-size: 10px;
      }
    }
  }
}

// order status modal
.single-update-status {
  ul {
    li {
      display: block;
      width: 100%;
      padding: 4px 10px;
      margin-bottom: 10px;
      background: #eae3e3;
      cursor: pointer;
      border-radius: 4px;
      transition: all 0.3s linear;
      position: relative;
      &.cross {
        background: #dcdcbb63;
        position: relative;
      }
      &.correct {
        background: #34a852;
        color: #fff;
        position: relative;
        transition: all 0.3s linear;
        &:hover {
          background: #34a852;
        }
      }
      &:hover {
        background-color: #ae2525 !important;
        color: #fff;
        &:after {
          opacity: 1;
          visibility: visible;
        }
      }
      &:after {
        content: "";
        position: absolute;
        right: 10px;
        border-left: 3px solid #fff;
        border-top: 10px solid transparent;
        border-right: 16px solid transparent;
        border-bottom: 3px solid #fff;
        transform: rotate(-60deg) translateY(-19%);
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}
