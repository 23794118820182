.page-heading-content {
  h2 {
    font-size: 26px;
    font-weight: 700;
    line-height: 36px;
    color: #3A494E;
    margin-bottom: 0;
    span {
      font-weight: 700;
      font-size: 13px;
      color: #A1A2A3;
    }
  }
  span {
    font-size: 24px;
    font-weight: 700;
    line-height: 32.4px;
  }
}
.top-heading-content {
  .page-heading-button-group {
    overflow: hidden;
    float: right;
    .single-button {
      display: inline-block;
      margin-right: 14px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}