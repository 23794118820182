.radius-btn {
  min-width: 120px;
  border-radius: 20px;
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  padding: 6px 15px;
}
.radius-btn-shadow {
  min-width: 120px;
  border-radius: 20px;
  font-size: 11px;
  font-weight: 700;
  text-align: center;
  padding: 3px 12px;
  box-shadow: 1px 1px 3px 0 #dadada;
}
.active-btn {
  color: #34a852;
  background-color: #e6f4ea;
}
.inactive-btn {
  color: #d1011d;
  background-color: #feeeed;
}
.reject-btn {
  color: #d1011d;
  background-color: #feeeed;
}
.pending-btn {
  background-color: rgba(250, 180, 0, 0.2);
  color: #fab400;
}
//  radius status btn
.radius-status-btn {
  border-radius: 20px;
  padding: 3px 10px;
  font-size: 13px;
  font-weight: 500;
  min-width: 40px;
  text-align: center;
  min-width: 120px;
}
.radius-pending-btn {
  background-color: rgba(250, 180, 0, 0.2);
  color: #fab400;
}
.radius-partial-btn {
  background-color: #1198d633;
  color: #1198d633;
}
.radius-processing-btn {
  background-color: rgba(153, 193, 9, 0.2);
  color: #99c109;
}
.radius-ready-to-ship-btn {
  background-color: rgba(67, 132, 243, 0.2);
  color: #4384f3;
}
.radius-shipped-btn {
  background-color: rgba(68, 180, 219, 0.2);
  color: #44b4db;
}
.radius-delivered-btn {
  background-color: rgba(52, 168, 82, 0.2);
  color: #34a852;
}
.radius-cancelled-btn {
  background-color: rgba(230, 45, 38, 0.2);
  color: #e62d26;
}
//   payment status btn
.payment-status-btn {
  border-radius: 20px;
  background-color: rgb(244, 250, 189);
  color: #215ac4;
  padding: 3px 10px;
  font-weight: 500;
  font-size: 13px;
  min-width: 120px;
  min-width: 120px;
  text-align: center;
}
.payment-method-cod {
  background-color: rgba(250, 180, 0, 0.2);
  color: #95ca58;
}
.payment-method-sslcommerce {
  background-color: rgba(13, 115, 235, 0.2);
  color: #0d73eb;
}
.payment-method-cbl {
  background-color: rgba(187, 21, 28, 0.2);
  color: #bb151c;
}
.payment-method-bkash {
  background-color: rgba(223, 20, 110, 0.2);
  color: #df146e;
}
.payment-method-nagad {
  background-color: rgba(236, 28, 36, 0.2);
  color: #ec1c24;
}
.payment-method-emi {
  background-color: rgba(42, 93, 171, 0.2);
  color: #2a5dab;
}
.payment-method-visa {
  background-color: rgba(26, 31, 113, 0.2);
  color: #1a1f71;
}
.payment-method-mastercard {
  background-color: rgba(247, 158, 27, 0.2);
  color: #f79e1b;
}

.bulk-product-btn:hover {
  background-color: #fff;
  border: 1px solid #c8c8c8;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  color: #34a852;
}
.bulk-product-btn:focus {
  background-color: #fff;
  border: 1px solid #c8c8c8;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  color: #34a852;
}
.yes-btn {
  padding: 3px 10px;
  color: #34a852;
  background-color: #e6f4ea;
  line-height: 18px;
  border-radius: 4px;
  font-weight: 700;
}
.no-btn {
  padding: 3px 10px;
  color: #d1011d;
  background-color: #feeeed;
  line-height: 18px;
  border-radius: 4px;
  font-weight: 700;
}

.bulk-product-btn.btn-primary.disabled,
.btn-primary:disabled {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.edit-btn-area {
  border: none;
  background: transparent;
  padding: 0;
  > a {
    > span {
      color: #fff;
      border-radius: 4px;
      font-weight: 500;
      padding: 7px 10px;
      background: #28a745 !important;
    }
  }
  > span {
    color: #fff;
    background-color: #dc3545 !important;
    border-radius: 4px;
    font-weight: 500;
    padding: 7px 10px;
  }
}
.add-area-btn {
  padding: 0 !important;
  svg {
    padding-right: 5px;
  }
  .add-link {
    color: #34a852;
    padding: 4px 6px;
  }
}
.edit-btn-area {
  &:hover {
    span {
      color: #fff;
    }
  }
}
.update-status {
  select {
    width: 100%;
    height: 40px;
    border: 1px solid #ddd;
    border-radius: 2px;
    padding: 0 5px;
  }
}
.btn-disabled {
  color: rgba(0, 0, 0, 0.25);
  padding: 4px 10px;
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
  border: none;
  height: 40px;
}
.btn-active {
  padding: 4px 10px;
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
  border-radius: 2px;
  border: none;
  height: 40px;
}
/* status update selected btn */
.status-update-selected-btn {
  position: relative;
  background: #ae2525 !important;
  color: #fff;
  &:after {
    content: '';
    position: absolute;
    right: 10px;
    border-left: 3px solid #fff;
    border-top: 10px solid transparent;
    border-right: 16px solid transparent;
    border-bottom: 3px solid #fff;
    opacity: 1 !important;
    visibility: visible !important;
    transform: rotate(-60deg) translateY(-19%);
  }
}
.product-type-btn {
  padding: 4px 10px;
  border-radius: 6px;
  min-width: 180px;
  color: #0865229e;
}
.product-type-not-available-btn {
  padding: 4px 10px;
  border-radius: 6px;
  min-width: 180px;
  color: #ec297b;
}
.edit-btn {
  color: #666;
  border-radius: 4px;
  font-weight: 500;
  padding: 4px 10px;
  border: none;
  background: #e0e8e2 !important;
}
.view-btn {
  color: #666;
  border-radius: 4px;
  font-weight: 500;
  padding: 4px 10px;
  background: #e8e6e4 !important;
  border: none;
}
.remove-hot-new-btn {
  display: inline-block;
  padding: 4px 12px;
  background: #d86161;
  color: #fff;
  border-radius: 4px;
}

button.add-submit-btn.ant-btn[disabled] {
  background: $input-field-color !important;
}