@import "./variables";

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  color: $primary-text-color;
  font-size: $primary-font-size;
  line-height: $primary-line-height;
  font-weight: $primary-font-weight;
  font-family: $primary-font;
}

img {
  max-width: 100%;
  height: auto;
  pointer-events: none;
}

p {
  margin: 0;
  color: $primary-text-color;
  font-size: $primary-font-size;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0;
  padding: 0 0;
  font-family: $primary-font;
}

h1 {
  font-size: 48px;
  line-height: 60px;
  font-weight: 700;
  font-family: $primary-font;
}

h2 {
  font-size: 45px;
  line-height: 58px;
  font-weight: 700;
  font-family: $primary-font;
}

h3 {
  font-size: 25px;
  line-height: 36px;
  font-weight: 700;
  font-family: $primary-font;
}

h4 {
  font-size: 18px;
  line-height: 30px;
  font-weight: 700;
  font-family: $primary-font;
}

h5 {
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  font-family: $primary-font;
}

h6 {
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  font-family: $primary-font;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  outline: none !important;
  font-family: $primary-font;
}

a:active,
a:hover {
  text-decoration: none;
  outline: 0 none;
  font-family: $primary-font;
}

.hidden {
  overflow: hidden;
}

i {
  font-family: "fontawesome";
  font-style: inherit;
}

.form-control:focus {
  background-color: #fff;
  outline: 0;
  box-shadow: none;
}
input:focus {
  outline: 0 !important;
  box-shadow: none !important;
  background: none;
  border: none !important;
}

textarea:focus {
  border: 1px solid transparent !important;
  box-shadow: none;
  border-radius: 0 !important;
  outline-color: #345995 !important;
}

blockquote {
  padding: 25px 35px;
  margin-left: 30px;
  background: #f5f5f5;
  margin: 30px 0 30px 30px;
  -webkit-box-shadow: -5px 0 #232323;
  -ms-box-shadow: -5px 0 #232323;
  box-shadow: -5px 0 #232323;
}

blockquote p:last-child {
  font-weight: 600;
  margin-bottom: 0;
}

::-moz-selection {
  text-shadow: none;
  color: #ffffff;
}

::selection {
  text-shadow: none;
}

@-webkit-keyframes autofill {
  to {
    color: inherit;
    background: transparent;
  }
}
input:disabled {
  background: #cccccc70 !important;
}
input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

input[type="submit"] {
  cursor: pointer;
  background: #e62d26;
}
input[type="submit"]:focus {
  background: #e62d26;
  outline: none;
}
.no-padding {
  padding: 0;
}
.submit-form {
  background: #e62d26;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  max-width: 300px;
  min-height: 42px;
  border: none;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  width: 100%;
}
.mt-27 {
  margin-top: 27px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-25 {
  margin-top: 25px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-25 {
  margin-bottom: 25px;
}
.white-bg {
  background-color: #fff;
}
.section-padding-15 {
  padding-left: 15px;
  padding-right: 15px;
}
.section-padding {
  padding: 15px 0;
}
.section-padding-bottom {
  padding-bottom: 15px;
}
.success-color {
  color: #34a852;
}
.failed-color {
  color: #d1011d;
}
.status-btn {
  font-size: 13px;
  font-weight: 700;
  border-radius: 20px;
  display: inline-block;
  padding: 5px 20px;
}
.success-btn {
  background-color: #e6f4ea;
  color: #34a852;
}
.failed-btn {
  background-color: #feeeed;
  color: #d1011d;
}
.name {
  color: #777171;
  font-size: 13px;
  font-weight: 500;
}
.table-name-heading {
  font-size: 13px;
  font-weight: 700;
  color: #777171;
}
.row--aligned [class*="col-"] {
  display: flex;
}
.detais-dot-wrapper {
  img {
    margin-right: 3px;
    &:last-child {
      margin-right: 0;
    }
  }
}
.dots-horizontal {
  img {
    margin-right: 3px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.title {
  border-bottom: 1px solid #e73d42;
  position: relative;
  color: #777171;
  font-size: 18px;
  margin-bottom: 15px;
  padding-bottom: 8px;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    width: 80px;
    background: #e32128;
  }
}
.title-2 {
  border-bottom: 1px solid #e73d42;
  position: relative;
  color: #777171;
  font-size: 18px;
  margin-bottom: 15px;
  padding-bottom: 8px;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    width: 80px;
    background: #e32128;
  }
}
.primary-submit-btn {
  background: #34a852;
  border-radius: 2px;
  font-weight: 700;
  font-size: 16px;
  min-width: 80px;
  color: #fff;
  border: none;
  height: 38px;
  width: 100%;
}
.print-btn {
  background: #ffffff;
  border: 1px solid #c8c8c8;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 5px 10px;
  span {
    display: inline-block;
    padding-left: 5px;
  }
}
button[disabled],
.ant-btn-dangerous[disabled] {
  cursor: no-drop;
  background: #b6a4a43b !important;
  border: gray !important;
  // color: #ccc !important;
}
.transparent-btn {
  padding: 4px 15px;
  background: #ffffff;
  border: 1px solid #c8c8c8;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  svg {
    color: #cb0000;
    margin-right: 5px;
  }
}
.processing-btn {
  color: #34a852;
  background-color: #e6f4ea;
  border: none;
}
.pending-btn {
  color: #0d73eb;
  background-color: #cbddff;
  border: none;
}
.reset-btn {
  background: #17a2b8;
  color: #fff;
  border: none;
  height: 38px;
  line-height: 38px;
  display: inline-block;
  padding: 0 20px;
  font-weight: 500;
  border-radius: 2px;
  font-size: 14px;
}
.download-sample-file {
  background: #ffffff;
  border: 1px solid #c8c8c8;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 4px 10px;
  font-size: 14px;
  color: #777171;
  height: 40px;
  svg {
    margin-left: 4px;
    margin-right: 5px;
  }
}
.download-sample-file-primary {
  background: #1890ff;
  border: none;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 4px 10px;
  font-size: 14px;
  color: #fff;
  height: 40px;
  svg {
    margin-left: 4px;
    margin-right: 5px;
    fill: #fff;
  }
}
.single-order-statuslog {
  border-radius: 2px;
  background: #3a494e;
  padding: 3px 8px;
  color: #fff;
  font-size: 10px;
  font-weight: 700;
}
input:-internal-autofill-selected {
  background-color: rgb(255, 255, 255) !important;
  background-image: none !important;
  color: rgb(0, 0, 0) !important;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}
input:-webkit-autofill {
  background-color: rgb(255, 255, 255) !important;
  background-image: none !important;
  color: rgb(0, 0, 0) !important;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}
.add-submit-btn {
  max-width: 45%;
  min-width: 200px;
  background-color: #34a852 !important;
  height: 40px !important;
  text-align: center;
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #fff !important;
  border: none !important;
}
.reset-submit-btn {
  max-width: 45%;
  min-width: 200px;
  background-color: #faad14 !important;
  height: 40px !important;
  text-align: center;
  font-weight: 700 !important;
  font-size: 16px !important;
  color: #fff !important;
  border: none !important;
}
.add-btn {
  border: 1px solid #34a852 !important;
  border-radius: 4px;
  display: flex !important;
  align-items: center;
  span {
    display: inline-block;
    margin-left: 6px;
    color: #34a852;
  }
}
// disabled add btn
.add-btn[disabled],
.ant-btn.add-btn-primary[disabled] {
  background: #f5f5f5;
  color: rgba(0, 0, 0, 0.25);
  border: none !important;
  span {
    color: #ddd;
  }
}
// table limited text show
.table-text-hide {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 156px;
  max-width: 156px;
}
// product details heading
.produt-details-heading {
  font-weight: $bold;
  font-size: 16px;
  line-height: 22px;
  color: #e62d26;
}

/*=======================
input field search btn
========================*/
.input-field-search-btn {
  .ant-input-search-button {
    height: 40px;
  }
}

.d-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-flex-l {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.d-flex-r {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.d-flex-base {
  display: flex;
  align-items: baseline;
}

.d-flex-sb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.d-flex-dir-col {
  flex-direction: column;
}

.d-flex-wrap {
  flex-wrap: wrap;
}

.d-flex-vt {
  align-items: baseline;
}

.m-10 {
  margin: 10px;
}
.mt-10 {
  margin-top: 10px;
}
.mr-10 {
  margin-right: 10px;
}
.mb-10 {
  margin-bottom: 10px;
}
.ml-10 {
  margin-left: 10px;
}

.m-15 {
  margin: 15px;
}
.mt-15 {
  margin-top: 15px;
}
.mr-15 {
  margin-right: 15px;
}
.mb-15 {
  margin-bottom: 15px;
}
.ml-15 {
  margin-left: 15px;
}

.height-32 {
  height: 32px;
}
.height-37 {
  height: 40px;
}
.width-full {
  width: 100%;
}
.red-txt {
  color: $theme-color;
}
.txt-center {
  text-align: center;
}
.txt-sm {
  font-size: 12px;
}
.txt-md {
  font-size: 14px;
}
// global notification
.notification-bar {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    height: 7px;
    width: 7px;
    background: #34a852;
    border-radius: 50%;
  }
}
/*global password field*/
.form-password {
  .ant-input-password {
    .ant-input {
      border: none !important;
      height: 28px !important;
    }
  }
}

.p-5 {
  padding: 5px;
}
.pl-5 {
  padding-left: 5px;
}
.pr-5 {
  padding-right: 5px;
}
.pt-5 {
  padding-top: 5px;
}
.pb-5 {
  padding-bottom: 5px;
}

.p-10 {
  padding: 10px;
}
.pl-10 {
  padding-left: 10px;
}
.pr-10 {
  padding-right: 10px;
}
.pt-10 {
  padding-top: 10px;
}
.pb-10 {
  padding-bottom: 10px;
}

.p-15 {
  padding: 15px;
}
.pl-15 {
  padding-left: 15px;
}
.pr-15 {
  padding-right: 15px;
}
.pt-15 {
  padding-top: 15px;
}
.pb-15 {
  padding-bottom: 15px;
}

.m-5 {
  margin: 5px;
}
.ml-5 {
  margin-left: 5px;
}
.mr-5 {
  margin-right: 5px;
}
.mt-5 {
  margin-top: 5px;
}
.mb-5 {
  margin-bottom: 5px;
}

.m-10 {
  margin: 10px;
}
.ml-10 {
  margin-left: 10px;
}
.mr-10 {
  margin-right: 10px;
}
.mt-10 {
  margin-top: 10px;
}
.mb-10 {
  margin-bottom: 10px;
}

.m-15 {
  margin: 15px;
}
.ml-15 {
  margin-left: 15px;
}
.mr-15 {
  margin-right: 15px;
}
.mt-15 {
  margin-top: 15px;
}
.mb-15 {
  margin-bottom: 15px;
}

.m-auto {
  margin: auto;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.mt-auto {
  margin-top: auto;
}
.mb-auto {
  margin-bottom: auto;
}

.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
.order-4 {
  order: 4;
}

.pointer {
  cursor: pointer;
}
.note-description {
  textarea {
    width: 100%;
    height: 73px;
    border: 1px solid #ddd;
    padding: 10px;
    &:focus {
      border: 1px solid #ddd !important;
    }
  }
}

.highlighted-txt {
  color: $theme-color;
}

.width-100 {
  width: 100%;
}

.width-auto {
  width: auto;
}

.waiting_for_vehicle_info {
  color: rgb(204, 122, 0);
  background-color: rgba(255, 154, 2, 0.301);
}

.waiting_for_personal_info {
  color: rgb(158, 155, 0);
  background-color: rgba(255, 218, 6, 0.274);
}

.waiting_for_verification {
  color: rgba(149, 160, 0, 0.986);
  background-color: rgba(255, 217, 0, 0.267);
}

.verified {
  color: rgba(19, 160, 0, 0.986);
  background-color: rgba(28, 201, 5, 0.26);
}

.inactive {
  color: rgba(255, 51, 0, 0.911);
  background-color: rgba(255, 217, 0, 0.267);
}

.form-row-half {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  > div {
    flex-grow: 1;
    margin-right: 15px;
  }

  > div:last-child {
    margin-right: 0;
  }

  .ant-form-item-control-input-content {
    height: 40px;
  }

  input,
  select {
    height: 32px;
  }
}

.shopEditModalForm,
.riderInformation {
  .ant-input:focus {
    border: 1px solid #40a9ff !important;
  }
  .ant-input:hover {
    border: 1px solid #40a9ff !important;
  }
}

.single-order-form {
  .ant-input:focus,
  .ant-input-focused {
    background: #fff !important;
    border-color: #40a9ff !important;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2) !important;
  }
}
