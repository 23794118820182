.cssanimation,
.cssanimation span {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both
}
.cssanimation span {
	display: inline-block
}
.leSnake{
   -webkit-animation: leSnake 1.5s ease-in-out;
   animation: leSnake 1.5s ease-in-out;
   -webkit-animation-iteration-count: infinite;
   animation-iteration-count: infinite
}

@-webkit-keyframes leSnake {
   from,
   to {
      -webkit-transform: translateY(0);
      transform: translateY(0)
   }
   50% {
      -webkit-transform: translateY(10px);
      transform: translateY(10px)
   }
}

@keyframes leSnake {
   from,
   to {
      -webkit-transform: translateY(0);
      transform: translateY(0)
   }
   50% {
      -webkit-transform: translateY(10px);
      transform: translateY(10px)
   }
}