.role-btn {
  padding: 4px 10px;
  border-radius: 6px;
  min-width: 250px;
  color: #0865229e;
  background: #0a3c1812;
  text-align: center;
}
.admin {
  background: #e6f4ea;
  padding: 4px 10px;
  border-radius: 6px;
  min-width: 250px;
  text-align: center;
  color: #34a852;
}
.business-one {
  background: #e7f1fe;
  padding: 4px 10px;
  border-radius: 6px;
  min-width: 250px;
  text-align: center;
  color: #ec297b;
}
.business-two {
  background: #e7f1fe;
  padding: 4px 10px;
  border-radius: 6px;
  min-width: 250px;
  text-align: center;
  color: #b4506b;
}
.business-three {
  background: #e7f1fe;
  padding: 4px 10px;
  border-radius: 6px;
  min-width: 250px;
  text-align: center;
  color: #4384f3;
}
.content-one {
  background: #e7f1fe;
  padding: 4px 10px;
  border-radius: 6px;
  min-width: 250px;
  text-align: center;
  color: #dbb270;
}
.content-two {
  background: #e7f1fe;
  padding: 4px 10px;
  border-radius: 6px;
  min-width: 250px;
  text-align: center;
  color: #ef4b29;
}
.content-three {
  background: #e7f1fe;
  padding: 4px 10px;
  border-radius: 6px;
  min-width: 250px;
  text-align: center;
  color: #ef4b29;
}
