.success {
  color: #fff;
  background-color: green;
  padding: 1px 12px !important;
}
.pending {
  color: #fff;
  background: green !important;
  padding: 1px 12px !important;
}
.warning {
  color: #fff;
  background-color:yellow;
  padding: 1px 12px !important;
}
.picked {
  color: #fff;
  background-color: orange;
  padding: 1px 12px !important;
}
.shipped {
  color: #fff;
  background-color: blue;
  padding: 1px 12px !important;
}
.wrong {
  color: #fff;
  background-color: red;
  padding: 1px 12px !important;
}
.cancelled {
  color: #fff;
  background-color: red;
  padding: 1px 12px !important;
}
.processing {
  color: #fff;
  background-color: orchid !important;
  padding: 1px 12px !important;
}
.delivered {
  color: #fff;
  background-color: #34a852 !important;
  padding: 1px 12px !important;
}
.refunded {
  color: #fff;
  background-color: #F52A48;
  padding: 1px 12px !important;
}
.returned {
  color: #fff;
  background-color: #570310;
  padding: 1px 12px !important;
}