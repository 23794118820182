
.k-pdf-export div {
  font-size: 10pt;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.pdf-page {
  position: relative;

  margin: 0 auto;

  margin-bottom: 0.25in;

  padding: 0.2in 0.3in;

  color: #333;

  background-color: #fff;

  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);

  box-sizing: border-box;
}

.pdf-header {
  padding-bottom: 0.1in;

  margin-bottom: 0.1in;

  border-bottom: 1px solid #e5e5e5;
}

.invoice-number {
  padding-top: 0.1in;

  float: right;
}

.pdf-footer {
  position: absolute;

  bottom: 0.25in;

  left: 0.3in;

  right: 0.3in;

  padding-top: 0.05in;

  border-top: 1px solid #e5e5e5;

  font-size: 0.9em;

  text-align: left;

  color: #787878;
}

.addresses {
  font-size: 12px;
}

.addresses:after {
  display: block;

  content: "";

  clear: both;
}

.for {
  float: left;
}

.from {
  float: right;
}

.addresses p {
  color: #787878;

  padding: 0.05in 0;

  border-top: 1px solid #e5e5e5;
}

.addresses p:first-of-type {
  border-top: 0;
}

.pdf-body {
  margin-top: 0.3in;
}

.company-logo {
  font-size: 1.8em;

  font-weight: bold;

  color: #3aabf0;
}

.signature {
  padding-top: 0.3in;
}

/* Dimensions other than px and em should be divided by 1.33 for the proper PDF output */

.size-a4 {
  width: 6.2in;

  height: 8.7in;
}

.size-letter {
  width: 6.3in;

  height: 8.2in;
}

.size-executive {
  width: 5.4in;

  height: 7.8in;

  font-size: 12px;
}

.size-executive .pdf-header {
  margin-bottom: 0.1in;
}

.box {
  padding: 1em;

  background-color: rgba(20, 53, 80, 0.038);

  border: 1px solid rgba(20, 53, 80, 0.05);
}
