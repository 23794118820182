// colors
$primary-color: #323232;
$primary-bg-color: #e62d26;
$theme-color: #e62d26;
$accent-color: #e62d26;
$accent-color2: #008095;
$accent-light: #fc9c9a;
$primary-font-size: 16px;
$primary-font-weight: 400;
$primary-font: "Uni Neue", sans-serif;
$primary-line-height: 25px;
$primary-text-color: #323232;
$primary-info-color: #2192d9;
$heading-color: #777171;
$gray-bg: #f0f2f5;

// input field
$input-field-color: #939393;
$white-bg: #fff;
$input-border-radius: 2px;
$input-field-height: 40px;
$input-field-border: 1px solid rgba(17, 43, 134, 0.1);
$shadow: 0 0 transparent, inset 0 0 0 1px rgba(17, 43, 134, 0.1);

// font-weight
$semibold: 500;
$bold: 700;
$normal: 400;
$thin: 300;

// Breakpoints for responsive devices...
$bp-desktop2: 1460px;
$bp-desktop: 1440px;
$bp-laptop: 1220px;
$bp-tablet: 976px;
$bp-mobile: 425px;
