

.log{
    width: 100vw;
    height: 100vh;
    margin-left: 0;
}
.headerback{
    background-color: white;
    //border-color: rgb(231, 231, 231);
    //position: fixed;
    
}
.footerback{
    background-color:#3A494E;
    height: 505px ;
    //width: 100%;
    //overflow-y: overlay ;
    
}
.back{
    //overflow-x: overlay;
    //background-color: #cc3a3a;
    //position:inherit;
    height: 100vh;
}
.sticky {
    position: fixed;
    top: 0;
    width: 100%
  }
  .footerheight{
      height: 433px;
  }
  .ff1{
      height: 420px;
      width:55%;
    //   @include tablet-portrait-max {
    //     //height: 420px;
    //     width:50%;
    //    }
  }
  .ff2{
      height: 420px;
      width:45%;
  }
  .ff1-1{
      height: 420px;
      width:70%;
      
  }
  .ff1-2{
    height: 420px;
    width:30%;
    
}
.ff2-1{
    height: 420px;
    width:40%;
    // @include tablet-portrait-max {
    //     //height: 420px;
    //     width:30%;
    //    }
}
.ff2-2{
  height: 420px;
  width:60%;
//   @include tablet-portrait-max {
//     //height: 420px;
//     width:70%;
//    }
}
.number{
    font-size: 30px;
    // @include tablet-portrait-max {
    //    font-size: 20px;
    //    margin-left: 12px;
    //   }
    //   @include mobile-portrait-max {
    //     font-size: 15px;
    //     margin-left: 12px;
    //    }
}
.mobilefont{
    font-size: 13px;
    
    //     @include tablet-portrait-max {
    //     font-size: 10px;
    //     margin-left: 12px;
    //    }
    //    @include mobile-portrait-max {
    //     font-size: 8px;
    //     margin-left: 12px;
    //    }
}
.information{
    font-size: 20px;
    // @include tablet-portrait-max {
    //     font-size: 12px;
    //     margin-left: 3px;
    //    }
    //    @include mobile-portrait-max {
    //     font-size: 10px;
    //     margin-left: 3px;
    //    }
}
.infor{
    font-size: 14px;
    margin-top: 4px;
    margin-bottom: 10px;
    // @include tablet-portrait-max {
    //     font-size: 10px;
    //     margin-left: 3px;
    //    }
    //    @include mobile-portrait-max {
    //     font-size: 8px;
    //     margin-left: 3px;
    //    }
}
.copyrightInfor{
    font-size: 14px;
    margin-top: 26px;
    //margin-bottom: 10px;
    // @include tablet-portrait-max {
    //     font-size: 10px;
    //     margin-left: 5px;
    //    }
    //    @include mobile-portrait-max {
    //     font-size: 8px;
    //     margin-left: 3px;
    //    }
}
.height{
    height: 78px;
}
.picture1{
    //background-image: url("../images/logos/appstore.png");
    @include tablet-portrait-max {
    height:25px;
    width:65px;

    }
}
.margin{
    // @include tablet-portrait-max {
    //   margin-left: 12px;
    
    //     }
}
.istyle{
    font-size: 22px;
    margin-left: 5px;
    margin-top: 5px;
    color:red;
    // @include tablet-portrait-max {
    //     font-size: 15px;
    //     margin-left: 4px;
    //     margin-top: -3%;
    // }
}
.iback{
    background-color: white;
    height:32px;
    width: 32px;
    margin-left: 45px;
    margin-top: 30px;
    // @include tablet-portrait-max {
    // margin-left: 24px;
    // margin-top: 24px;
    // height:22px;
    // width: 22px;
    // }
    // .anticon{
    //     color:red;
    // }
}
.iback2{
    background-color: white;
    height:32px;
    width: 32px;
    margin-left: 15px;
    margin-top: 30px;
    // @include tablet-portrait-max {
    // margin-left: 7px;
    // margin-top: 24px;
    // height:22px;
    // width: 22px;
    // }
    .anticon{
        color:red;
    }
}
.displaystyle{
    display: flex;
    @include mobile-portrait-max {
        //display: block;
    }
}
.backimage{
    // background-image: url("../images/background/Rectangle.png");
    height:740px;
    margin-top: -0.5%;

    background-size: cover;
    background-position:bottom;
    //position: fixed;
    background-repeat: no-repeat;
    // @include tablet-portrait-max {
    //     //margin-left: -40%; 
    //     margin-top: -10%;
    //     padding:20px;
    //     height:80%;

    // }
    // @include mobile-portrait-max {
    //     //margin-left: -40%; 
    //     //margin-top: -106%;
    //     height:60%;
    //     //padding:20px;

    // }
}

.dlogo{
    //margin-left: 98px;
    margin-top:20px;
    width:160px;
    //height:50px;
    background-image: url("../../images/logo.svg");
    background-repeat: no-repeat;
    //background-size: cover;
    @include tablet-portrait-max {
        //margin-left: 20px;
       
    }
    
}
.app{
    //margin-left: 20px;
    margin-top:25px;
    width:30px;
    height:50px;
    font-size: 23px;
    color:#E62D26;
    @include tablet-portrait-max {
    //display: none;
    margin-left: 8px;
    }
    
}
.inputt {
    height: 40px;
    width: 780px;
    //margin-left: 50px;
    font-size: 20px;
    border-color: lightgray;
    // @include tablet-portrait-max {
    //   //height: 40px;
    //  // width: 250px;
    //   //margin-left: 0px;
    //   display:none;
    // }
  }
  .size{
    height: 40px;
    width: 42px;
    
  }
  .searchsize{
    height: 40px;
    width: 50px;
    // @include tablet-portrait-max {
    //     display: none;
    // }
  }
  .ok{
    // @include tablet-portrait-max {
    //     display: none;
    // } 
  }
  .dimension{
    //width:100%;
    background-color: white;
}
  .dimension1{
      width:23%;
      //background-color: yellow;
    //   @include tablet-portrait-max {
    //     width:40%;
    // }
  }
  .dimension2{
    width:60%;
    //background-color: rgb(173, 173, 158);
    // @include tablet-portrait-max {
    //     width:23%;
    // }
}
  .dimension3{
    width:17%;
    //background-color: yellow;
    // @include tablet-portrait-max {
    //     width:37%;
    // }
}
  .mtstyle{
    // @include tablet-portrait-max {
    //    margin-left:-3%;
    //    padding-right: 2px;
    // }
  }
  
  .header1{
    width: 50%;
  }
  .header2{
    width: 50%;
}
.hd11{
    width: 30%;
}
.hd12{
    width: 70%;
}



.uff{
    width: 50%;
}
.headicon{
    font-size:16px;
    margin-top:0px;
    color:red;
}
.fontsize{
    font-size: 12px;
    margin-top: 12px;
    //margin-left: 5px;
    // @include tablet-portrait-max {
    //     display: none;
    // }
}
.becomestyle{
   color: #E62D26;
   margin-left: 98px;
//    @include tablet-portrait-max {
//     font-size: 22px;
// }
}
.dhamakastyle{
    color: rgb(88, 85, 85);
    margin-left: 98px;
    // @include tablet-portrait-max {
    //     font-size: 22px;
    // }
    
 }
 .whystyle{
    color: rgb(88, 85, 85);
    font-weight: 100;
    // @include tablet-portrait-max {
    // font-size: 30px;
    // }
 }
 .colorstyle{
    background-color: whitesmoke;
    
 }
 .morestyle{
    color: rgb(88, 85, 85);
    margin-left: 100px;
    // @include tablet-portrait-max {
    //     font-size: 12px;
    // }
    
 }
 .hheight{
    // @include tablet-portrait-max {
    //  height:52px;
    // }
 }
 .h2height{
    // @include tablet-portrait-max {
    //  height:415px;
    // }
 }
 .circle{
    // @include tablet-portrait-max {
    //  height:132px;
    //  width:230px;
    // }
 }
//  .square{
//      width:30%;
//      @include tablet-portrait-max {
//         //width:20%;
//        }
//  }
 .button1{
     margin-left: 98px;
     width: 160px;
    //  @include tablet-portrait-max {
    //     font-size: 12px;
    //     width:125px;
    //     height:40px;
    //    }
 }
 .button2{
    margin-left: 8px;
    background-color: #5B98DF;
    width: 160px;
    // @include tablet-portrait-max {
    //     font-size: 12px;
    //     width:125px;
    //     height:40px;
    //    }
}

.boxstyle{
    width:30%;
    // @include tablet-portrait-max {
    //     width:30%;
    //    }
}
.pic1{
    // background-image: url("../images/background/pic1.png");
    background-repeat: no-repeat;
    background-size: cover;
    // @include tablet-portrait-max {
    // height:195px;
    // }
}
.pic2{
    // background-image: url("../images/background/pic2.png");
    background-repeat: no-repeat;
    background-size: cover;
    // @include tablet-portrait-max {
    //     height:195px;
    //     }
}
.pic3{
    // background-image: url("../images/background/pic3.png");
    background-repeat: no-repeat;
    background-size: cover;
    // @include tablet-portrait-max {
    //     height:195px;
    //     }
}
.pic4{
    // background-image: url("../images/background/pic4.png");
    background-repeat: no-repeat;
    background-size: cover;
    
}
.pic5{
    // background-image: url("../images/background/pic5.png");
    background-repeat: no-repeat;
    background-size: cover;
}
.pic6{
    // background-image: url("../images/background/pic6.png");
    background-repeat: no-repeat;
    background-size: cover;
}
.ok2{
    font-size: 24px;
    color:  rgb(88, 85, 85);
    // @include tablet-portrait-max {
    //    font-size: 16px;
    //     }
}
.textstyle{
    font-size: 16px;
    color:  rgb(139, 136, 136);
    // @include tablet-portrait-max {
    //     font-size: 12px;
    //      }
}
.colorstyle2{
    background-color: #eeebeb;
    
 }
 .truck{
    // background-image: url("../images/logos/truck.svg");
    background-repeat: no-repeat;
    background-size: cover;
    //color:rgb(194, 191, 191);
 }
 .visibility{
    @include tablet-portrait-max {
       display: none;
         }
 }
.forlargescreen{
    @include desktop-landscape-min2 {
       
    grid-column-start: 2;
    grid-column-end: 12;
}
}
.mfordiv{
    @include desktop-landscape-min2 {
    margin-left: 7%;
    margin-right: 10%;
    }
}
.mfordiv2{
    @include desktop-landscape-min2 {
    margin-left: 5%;
    // margin-right: 10%;
    }
}
.mfordiv3{
    @include desktop-landscape-min2 {
    margin-left: 7%;
    // margin-right: 10%;
    }
}