.top-heading-products {
  .page-heading-button-group {
  }
}
.modal-body {
  padding: 30px;
  .react-csv-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    .csv-label {
      display: block;
      width: 100%;
      text-align: center;
    }
    .csv-input {
      text-align: center;
      margin-left: 105px;
    }
  }
}
