
.back {
  height: 750px;
  width: 550px;
  position: center;
  margin-left: 800px;
  margin-top: 100px;
  @include mobile-portrait-max {
    width: 330px;
    height: 650px;
    margin-left: 0px;
  }
}
.mstyle {
  font-size: 18px;
  //color: black;
  @include mobile-portrait-max {
    font-size: 12px;
    color: black;
  }
}
.luser,
.lunlock {
  height: 27px;
  max-width: 330px;
  margin: 35px auto 0px auto;
  background-color: white;
  @include mobile-portrait-max {
    width: 250px;
    margin-left: -10;
    //padding:10px;
  }
}
.pastyle {
  font-size: 18px;
  color: rgb(22, 21, 21);
  @include mobile-portrait-max {
    font-size: 12px;
  }
}

.iconstyle {
  font-size: 18px;
}
.by {
  font: Uni Neue;
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  @include mobile-portrait-max {
    font-size: 12px;
    font: Uni Neue;
    font-weight: 300;
    font-style: normal;
  }
}
.bt {
  @include mobile-portrait-max {
    //width:220px;
    align-items: center;
    margin-top: 8px;
    //padding: 0px 7px 0px 7px;
    padding-left: 5px;
    //padding-right: 5px;
  }
}
.next {
  background-color: #dc2626;
  color: white;
  font-size: 16px;
  height: 48px;
  width: 300px;
  display: block;
  margin: 13px auto auto;
  border-radius: 5px;
  @include mobile-portrait-max {
    width: 200px;
    //margin-left: 48px;
    margin-top: 8px;

    //margin-right: 48px;
  }
}
.already {
  font: Uni Neue;
  font-weight: 300;
  font-style: normal;
  @include mobile-portrait-max {
    font-size: 12px;
    font: Uni Neue;
    font-weight: 300;
    font-style: normal;
  }
}
.b {
  @include mobile-portrait-max {
    //padding: 5px 15px 5px 5px;
    position: center;
  }
}
.formobilev{
  @include mobile-portrait-max {
  padding: 0px;
}
}
