.search-box-shadow {
  .ant-select-show-arrow {
    width: 100%;
    .ant-select-selector {
      background: #ffffff;
      border: 1px solid #c8c8c8;
      box-sizing: border-box;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
    }
  }
}
.search-box-shadow {
  .ant-space {
    width: 100%;
    .ant-picker {
      background: #ffffff;
      border: 1px solid #c8c8c8;
      box-sizing: border-box;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      width: 100%;
    }
  }
}

// product search group area
.searching-area {
  .search-box-shadow {
    &.name-search {
      position: relative !important;
      height: 40px;
      &:first-child {
        flex: 1 1 50%;
        max-width: 50%;
      }

      .ant-select.ant-select-single.ant-select-show-arrow {
        width: 100%;
        height: 40px !important;
        // border: 1px solid #c8c8c8;
        box-sizing: border-box;
        // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        .ant-select-selection-search {
          left: 40px;
        }
        .ant-select-selection-placeholder,
        .ant-select-selection-item {
          line-height: 40px !important;
        }
        .ant-select-selector {
          height: 40px;
        }
        .ant-select-selection-placeholder {
          font-size: 16px;
        }
      }
      .search-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 12px;
      }
    }
  }
  .search-right-side-button-group {
    overflow: hidden;
    .search-box-shadow {
      float: left;
      margin-right: 10px;
      height: 40px;
      .ant-space {
        width: 100%;
        .ant-picker {
          background: #ffffff;
          border: 1px solid #c8c8c8;
          box-sizing: border-box;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
          border-radius: 4px;
          width: 100%;
          height: 40px;
        }
      }
      &.date-search-product {
        max-width: 224px;
        min-width: 224px;
        .ant-picker-input {
          input {
            color: #696969;
            font-weight: 500;
            font-size: 13px;
          }
        }
      }
      &.status-search {
        max-width: 221px;
        min-width: 221px;
        .ant-select-selector {
          height: 40px;
          border-radius: 4px;
          border: 1px solid #c8c8c8;
          box-sizing: border-box;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
          border-radius: 4px;
          .ant-select-selection-item {
            color: #696969 !important;
            font-weight: 500 !important;
            height: 40px !important;
            line-height: 40px;
          }
        }
      }
      &.add-search-box-shadow {
        button {
          height: 40px;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.ant-picker {
  height: 40px;
}
input {
  height: 40px;
}
// override select product name
.ant-select-single:not(.ant-select-customize-input) {
  .ant-select-selector {
    height: 40px !important;
  }
}

.ant-select-single {
  .ant-select-selector {
    .ant-select-selection-placeholder {
      line-height: 40px !important;
    }
  }
}
.search-filed {
  color: #c8c8c8 !important;
  height: 40px !important;
}
.auto-width-wrap-child {
  .ant-input-group {
    // width: auto !important;
    display: flex;
  }
}

.search_area {
  background: #fff;
  padding: 0.6rem 0.8rem;
  border-radius: 5px;
  margin-bottom: 1rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  .search_form {
    display: -webkit-box !important;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-top: 2px;
    &::-webkit-scrollbar {
      width: 3px;
      height: 0;
    }
    .ant-form-item {
      margin-bottom: 0;
    }
  }
  .search_btn {
    padding: 0 0 0 5px;
    margin: 3px 10px 2px 0;
    background: hsla(0, 0%, 100%, 0.03);
    z-index: 1;
  }
}
