.area-division-top-heading {
  h3 {
    margin-bottom: 4px;
  }
  ul {
    li {
      display: inline-block;
      margin-right: 35px;
      position: relative;
      color: #e62d26;
      font-weight: 500;
      &:after {
        content: '>';
        position: absolute;
        right: -24px;
        color: rgb(156, 153, 153);
        font-weight: 500;
      }
      &:last-child {
        margin-right: 0;
        &:after {
          display: none;
        }
      }
      a {
        color: #3a3a3a;
        font-weight: 500;
        transition: all 0.3s linear;
        &:hover {
          color: #e62d26;
        }
      }
    }
  }
}
