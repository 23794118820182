.top-heading-user-details {
  h3 {
    margin-bottom: 4px;
  }
  ul {
    li {
      display: inline-block;
      margin-right: 35px;
      position: relative;
      color: #e62d26;
      font-weight: 500;
      a {
        color: #3a3a3a;
        font-weight: 500;
        transition: all 0.3s linear;
        &:after {
          content: ">";
          position: absolute;
          right: -24px;
          color: #696969;
          font-weight: 500;
        }
        &:hover {
          color: #e62d26;
        }
      }
    }
  }
}
.user-deatils-area {
  .user-detals-wrapper-content {
    .user-details-inner-content {
      background: #fff;
      // border: 1px solid $gray-bg;
      padding: 30px 15px;
      padding-bottom: 15px;
      // border-bottom: none;
      margin-bottom: 15px;
      .user-image-content {
        .user-banner {
          margin: auto;
          display: block;
          text-align: center;
          border-radius: 50%;
          max-height: 280px;
          max-width: 280px;
          img {
            border-radius: 50%;
          }
        }
        .user-image-content-body {
          .body {
            h6 {
              margin-bottom: 7.5px;
              display: flex;
              flex-direction: column;
              align-items: center;
              svg {
                margin-bottom: 7.5px;
                margin-top: -10px;
              }
            }
            p {
              margin-bottom: 0;
            }
            span {
              display: inline-block;
              border-radius: 17px;
              font-size: 13px;
              font-weight: 700;
              text-align: center;
              color: #34a852;
              background-color: #e6f4ea;
              padding: 3px 13px;
            }
          }
        }
      }
    }
  }
  .user-information-tab-menu {
    background-color: #fff;
    min-height: 457px;
    .nav-item {
      a {
        font-size: 13px;
        font-weight: 700;
        color: #3a494e;
        position: relative;
        padding: 10px;
        span {
          svg {
            height: 20px;
            width: 20px;
          }
        }
        svg {
          margin-right: 20px;
          margin-left: 20px;
        }
        &.active {
          background-color: #feeeed;
          position: relative;
          color: $theme-color;
          &:before {
            top: 0;
            content: "";
            position: absolute;
            left: 0;
            height: 100%;
            border-left: 4px;
            width: 4px;
            background-color: $theme-color;
          }
        }
      }
    }
  }
  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    left: -47px;
    padding: 0;
    border: 0;
  }
  .user-information-show-tab-wrapper {
    background-color: #fff;
    padding: 15px 15px;
    min-height: 407px;
    .inner-content {
      .single-content {

          .single-information-user-wrapper {
            .single-information-user-content {
              display: flex;
              justify-content: flex-start;
              .info-icon {
                margin-top: 10px;
                height: 40px;
                width: 40px;
                background: $theme-color;
                text-align: center;
                line-height: 40px;
                border-radius: 50%;
                color: #fff;
                flex-shrink: 0;
              }
              .user-personal-inforamtion {
                width: 100%;
                margin-left: 20px;
                .inner-body-wrapper {
                  .inner-body {
                    .single {
                      display: flex;
                      justify-content: space-between;
                      margin-bottom: 10px;
                      .info-name {
                        color: gray;
                        span {
                        }
                      }
                      .info-desc {
                        width: 50%;
                        float: left;
                        text-align: left;
                        span {
                        }
                      }
                    }
                  }
                  .user-address-inner-body {
                    .single-user-address {
                      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
                      padding: 10px;
                      margin-bottom: 11px;
                      border-radius: 4px;
                      .single-info {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 9px;
                        .info-name {
                        }
                        .info-desc {
                          width: 50%;
                          float: left;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      
    }
  }
}

.top-heading-user-details {
  h3 {
    margin-bottom: 4px;
  }
  ul {
    li {
      display: inline-block;
      margin-right: 35px;
      position: relative;
      color: #e62d26;
      font-weight: 500;
      a {
        color: #3a3a3a;
        font-weight: 500;
        transition: all 0.3s linear;
        &:after {
          content: ">";
          position: absolute;
          right: -24px;
          color: #555;
          font-weight: 500;
        }
        &:hover {
          color: #e62d26;
        }
      }
    }
  }
}
.user-deatils-area {
  .user-detals-wrapper-content {
    .user-details-inner-content {
      background: #fff;
      // border: 1px solid $gray-bg;
      padding: 30px 15px;
      padding-bottom: 15px;
      // border-bottom: none;
      margin-bottom: 15px;
      .user-image-content {
        .user-banner {
          margin: auto;
          display: block;
          text-align: center;
          border-radius: 50%;
          max-height: 280px;
          max-width: 280px;
          img {
            border-radius: 50%;
          }
        }
        .user-image-content-body {
          .body {
            h6 {
              margin-bottom: 7.5px;
              display: flex;
              flex-direction: column;
              align-items: center;
              svg {
                margin-bottom: 7.5px;
                margin-top: -10px;
              }
            }
            p {
              margin-bottom: 0;
            }
            span {
              display: inline-block;
              border-radius: 17px;
              font-size: 13px;
              font-weight: 700;
              text-align: center;
              color: #34a852;
              background-color: #e6f4ea;
              padding: 3px 13px;
            }
          }
        }
      }
    }
  }
  .user-information-tab-menu {
    background-color: #fff;
    min-height: 457px;
    .nav-item {
      a {
        font-size: 13px;
        font-weight: 700;
        color: #3a494e;
        position: relative;
        padding: 10px;
        span {
          svg {
            height: 20px;
            width: 20px;
          }
        }
        svg {
          margin-right: 20px;
          margin-left: 20px;
        }
        &.active {
          background-color: #feeeed;
          position: relative;
          color: $theme-color;
          &:before {
            top: 0;
            content: "";
            position: absolute;
            left: 0;
            height: 100%;
            border-left: 4px;
            width: 4px;
            background-color: $theme-color;
          }
        }
      }
    }
  }
  .ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
    left: -47px;
    padding: 0;
    border: 0;
  }
  .user-information-show-tab-wrapper {
    background-color: #fff;
    padding: 15px 15px;
    min-height: 407px;
    .inner-content {
      .single-content {
        .tab-content {
          .single-information-user-wrapper {
            .single-information-user-content {
              display: flex;
              justify-content: flex-start;
              .info-icon {
                margin-top: 10px;
                height: 40px;
                width: 40px;
                background: $theme-color;
                text-align: center;
                line-height: 40px;
                border-radius: 50%;
                color: #fff;
                flex-shrink: 0;
              }
              .user-personal-inforamtion {
                width: 100%;
                margin-left: 20px;
                .inner-body-wrapper {
                  .inner-body {
                    .single {
                      display: flex;
                      justify-content: space-between;
                      margin-bottom: 10px;
                      .info-name {
                        span {
                        }
                      }
                      .info-desc {
                        width: 50%;
                        float: left;
                        text-align: left;
                        span {
                        }
                      }
                    }
                  }
                  .user-address-inner-body {
                    .single-user-address {
                      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
                      padding: 10px;
                      margin-bottom: 11px;
                      border-radius: 4px;
                      .single-info {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 9px;
                        .info-name {
                          text-align: left;
                        }
                        .info-desc {
                          width: 50%;
                          float: left;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.single {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  .info-name {
    span {
    }
  }
  .info-desc {
    width: 50%;
    float: left;
    text-align: left;
    span {
    }
  }
}
