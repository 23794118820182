.anticon {
  vertical-align: baseline !important;
}
.main-logo {
  max-height: 100px;
}
.ant-input-group .ant-input {
  height: 40px;
}
.ant-input-group {
  .ant-input {
    .ant-input-group-addon {
      button {
        height: 40px;
      }
    }
  }
}

.ant-input-group {
  display: flex !important;
  width: calc(100% - 50px) !important;
  .ant-input-group-addon {
    button {
      height: 40px;
    }
  }
}

.ant-select-auto-complete,
.ant-input-search {
  width: 100%;
  flex: 1 1 50%;
  min-width: 300px;
  margin-right: 15px;
}

.ant-input-search .ant-input:focus {
  border: 1px solid $theme-color !important;
}

.ant-layout-has-sider
  .ant-layout-sider.ant-layout-sider-collapsed
  .ant-layout-sider-trigger {
  left: 80px !important;
}

.ant-notification,
.ant-message {
  z-index: 1000000000 !important;
}

.ant-select-selection-search-input {
  height: 40px !important;
}
.ant-drawer-body {
  .ant-input-group-addon {
    min-width: 50px !important;
    padding: 8px 15px !important;
  }
}

.ant-btn-primary {
  color: #fff !important;
  border-color: #eb2127 !important;
  background: #eb2127 !important;
  color: #fff !important;
}

.ant-input-search-button {
  color: #fff !important;
  border-color: #000 !important;
  background: #000 !important;
  color: #eb2127 !important;
  text-shadow: 1px 1px 5px white;
}

.ant-modal-body {
  padding: 40px !important;
  padding-bottom: 20px !important;
}

.shop-sedebar .ant-card-body {
  padding: 0 !important;
  margin-top: 40px !important;
}

.heading-steps {
  width: 100%;
  overflow: auto;
  background: white;

  .ant-steps-item {
    margin-right: 30px;
    flex: 0 0 !important;
  }

  .ant-steps-item-container {
    margin-right: 30px;
    padding: 15px;
  }

  .ant-steps-navigation .ant-steps-item::after {
    top: 50% !important;
    margin-top: -14px !important;
  }

  .ant-steps-navigation .ant-steps-item::before {
    background-color: transparent !important;
  }
}

.mobile-custom-input {
  .ant-select-auto-complete,
  .ant-input-search {
    min-width: 260px;
    margin-right: 0px;
  }
}

.scanner-data-list{
  overflow: scroll;
  height: calc(100vh - 150px);
  
}