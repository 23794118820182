.breadcrum-style-one {
  h2 {
    font-size: 26px;
    line-height: 36px;
    color: #3a494e;
    font-weight: 700;
    margin-bottom: 5px;
  }
  ul {
    li {
      display: inline-block;
      margin-right: 9px;
      position: relative;
      font-size: 13px;
      font-weight: 700;
      color: #e62d26;
      a {
        font-size: 13px;
        font-weight: 700;
        line-height: 18px;
        color: #737373;
      }
      &:after {
        content: ">";
        font-size: 12px;
        font-weight: 700;
        margin-left: 9px;
        color: #696969;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  }
}
