.main-dashboard-page {
  .card-box {
    border-radius: 2px;
    text-align: center;
    padding: 10px;
    .content {
      .box-title {
        font-size: 19px;
        color: #3a3a3a;
        font-weight: 500;
      }
      .box-count {
        font-weight: 600;
        color: #3a3a3a;
        margin-bottom: 0;
      }
    }

    .box-svg {
    }
    &.box-one {
      background-color: rgba(67, 132, 243, 0.2);
    }
    &.box-two {
      background-color: rgba(68, 180, 219, 0.2);
    }
    &.box-three {
      background-color: #ff408152;
    }
  }
  .user-profile-wrapper {
    .inner-wrapper {
      max-width: 400px;
      margin: auto;
      background: #fff;
      .avatar-role {
        background: $theme-color !important;
        padding: 30px 0;
        border-radius: 2px;
      }
      .user-avatar {
        height: 80px;
        width: 80px;
        border-radius: 50%;
        margin: auto;
        img {
          border-radius: 50%;
        }
      }
      .role {
        margin-top: 5px;
        h4 {
          font-weight: 500;
          color: #fff;
        }
      }
    }
    .user-content {
      padding: 30px 0;
      h3 {
        margin-bottom: 0;
      }
      h4 {
      }
    }
  }
}
