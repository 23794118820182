.login-container {
  .login-banner {
    .login-banner-overlay {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: #081d23;
      background: linear-gradient(45 deg, red, black);
      background: linear-gradient(45deg, antiquewhite 45%, #008e9b4d);
      // background: antiquewhite;
      opacity: 0.5;
    }
  }
  .login-introduction {
    max-width: 560px;
    margin: auto;
    position: relative;
    top: 50%;
    transform: translateY(-100%);
    img {
    }
    h3 {
      font-weight: 300;
      font-size: 60px;
      // color: #f7f7f7;
      margin-top: 15px;
      margin-bottom: 15px;
      line-height: 60px;
      font-family: "Uni Neue";
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    .role-icon-lists {
      ul {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        li {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 15px;
          margin-bottom: 10px;
          height: 40px;
          width: 40px;
          border-radius: 50%;
          background: rgba(0, 0, 0, 0.52);
          text-align: center;
          color: #fff;
          line-height: 33px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
  .admin-login-wrapper {
    background: #fff;
    padding: 60px;
    min-height: 100vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2.d-title {
      font-weight: 700;
      color: $accent-color;
      margin-bottom: 15px;
      span {
        font-weight: 300;
      }
    }
    p {
      color: #666;
      font-weight: 300;
    }
    .admin-login-form {
      margin-top: 60px;
      width: 100%;
      max-width: 300px;
      text-align: center;
      display: block;
      // padding: 0 60px;
      .login-error {
        // margin-bottom: 28px;
        display: block;
        font-size: 0.8rem;
        color: $theme-color;
      }
      .single-form-field {
        border-bottom: 1px solid #ddd;
        // margin-bottom: 28px;
        padding-bottom: 2px;
        position: relative;
        svg {
          position: absolute;
          left: 0;
          top: 6px;
        }
        input {
          width: 100%;
          border: none;
          padding: 0 25px;
          color: #323232;
          font-size: 14px;
          padding-bottom: 5px;
          margin-left: 12px;
        }
        span {
          position: absolute;
          right: 16px;
          top: 8px;
        }
      }
      .input {
        margin-top: 20px;
      }
    }
    .password-auth {
      margin-top: 50px;
      a {
        display: inline-block;
        padding: 0 0;
        margin-right: 18px;
        color: #323232;
        font-size: 14px;
        font-weight: 400;
      }
      label {
        margin-left: 9px;
      }
      input {
        // height: auto;
      }
    }
    .get-started {
      input {
        margin-top: 19px;
      }
    }
  }
}
