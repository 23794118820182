.add-rider.drawer-toggle-wrapper {
  .drawer-toggle-inner-wrapper {
    .ant-form {
      display: flex;
      flex-wrap: wrap;
      h4 {
        flex: 1 1 100%;
        margin-top: 30px;
        margin-bottom: 15px;
      }
      .buttons-container {
        margin-top: 30px;
        flex: 1 1 100% !important;
      }
      .ant-form-item-control-input-content
        input:not(.ant-select-selection-search-input):focus {
        border: 1px solid #52c41a !important;
      }
      .ant-form-item-control-input-content .ant-picker-input input:focus,
      .ant-form-item-control-input-content .ant-picker-input input {
        border: none !important;
        background: transparent !important;
      }
      .year-picker {
        width: 100%;
      }

      > .ant-row.ant-form-item {
        flex: 1 1 calc(50% - 30px);
        display: flex;
        flex-direction: row;

        &:last-child {
          flex: 0 1 calc(50% - 30px);
        }
        
      }

      > div {
        margin-right: 15px;

        &:nth-child(2n + 1) {
          // margin-right: 0;
        }
      }
    }

    .ant-form-vertical {
      .drawwer-title {
        font-size: $heading-color;
        font-weight: $bold;
        line-height: 27px;
        text-transform: capitalize;
        margin-bottom: 16px;
        font-size: 20px;
      }
      .ant-form-item {
        margin-bottom: 27px;
        .ant-form-item-label {
          label {
            color: $heading-color;
            font-size: 14px;
            line-height: 19px;
            font-weight: $bold;
            padding-bottom: 0;
          }
        }
        .ant-form-item-control {
          .ant-form-item-control-input-content {
            .ant-select-selection-search {
              input {
                border: none;
                background-color: transparent;
                padding: 0;
                height: 40px;
              }
            }
            input {
              border: $input-field-border;
              // color: $input-field-color;
              background: $white-bg;
              border-radius: $input-border-radius;
              height: $input-field-height;
              font-size: 13px;
              font-weight: $semibold;
              padding: 0 8px;
              width: 100%;
            }
            textarea {
              resize: none;
            }
          }
        }
      }
      .ant-radio-group {
        .product-status-label {
          label {
            color: $heading-color;
            font-size: 14px;
            line-height: 19px;
            font-weight: $bold;
            padding-bottom: 0;
          }
        }
      }
      .product-active-inactive {
        display: inline-block;
        transition: all 0.3s linear;
        .ant-radio-wrapper {
          padding: 3px 6px;
          border-radius: 2px;
          min-width: 87px;
          max-width: 87px;
          border: $input-field-border;
          font-weight: 500;
          color: #c8c8c8;
        }
        .ant-radio-wrapper-checked {
          border: 1px solid #34a852;
          color: #34a852;
          .ant-radio-inner {
            border-color: #34a852;
            &:after {
              background-color: #34a852;
            }
          }
        }
      }
    }
  }
}

.ant-drawer-footer {
  border: none;
}
