.top-header-area {
  box-shadow: 0px 4px 4px rgba(231, 231, 231, 0.25);
  background: #fff;
  padding: 15px;
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: stretch;
  .admin-information-sidebar {
    float: right;
    ul {
      margin-bottom: 0;
      li {
        display: inline-block;
        margin-right: 27px;
        position: relative;
        margin-bottom: 0 !important;
        line-height: 40px;
        cursor: pointer;
        display: flex;
        align-items: center;
        &:last-child {
          margin-right: 0;
        }
        span {
          display: inline-block;
          padding: 0 8px 0 10px;
          font-weight: 500;
          color: #3a494e;
          font-size: 16px;
        }
        .drop-down-menu {
          position: absolute;
          min-width: 150px;
          max-width: 150px;
          box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.1);
          padding: 10px 15px;
          border-radius: 4px;
          opacity: 0;
          transition: all 0.3s linear;
          visibility: hidden;
          top: calc(100% + 15px);
          z-index: 99;
          background: #fff;
          // left: -15px;
          right: 0;
          
          li {
            display: block;
            line-height: 30px;
            transition: all 0.3s linear;
            a {
              color: #333;
              transition: all 0.3s linear;
            }
            button {
              border: none;
              background: transparent;
              width: 100%;
              text-align: left;
            }
            &:hover {
              padding-left: 5px;
              a {
                color: #d61e37;
              }
              button {
                color: #d61e37;
              }
            }
          }
        }
        &:hover {
          ul {
            opacity: 1;
            visibility: visible;
            top: 100%;
          }
        }
        &.thumb-menu-area {
          position: relative;
          span {
            padding: 0 !important;
          }
          .thumbs-menu {
            position: absolute;
            left: -100px;
            background: #fff;
            box-shadow: 1px 1px 11px 0px rgba(0, 0, 0, 0.25);
            z-index: 999;
            width: 300px;
            padding: 20px 20px;
            opacity: 0;
            transition: all 0.3s linear;
            top: 135%;
            visibility: hidden;
            ul {
              li {
                display: inline-block !important;
                margin-right: 0 !important;
                width: 33.33%;
                line-height: 29px !important;
                text-align: center;
                margin-bottom: 12px !important;
                padding-bottom: 6px;
                padding-top: 6px;
                a {
                  color: #d61e37 !important;
                  font-weight: 700;
                  font-size: 13px;
                  svg {
                    path {
                      fill: #d61e37;
                    }
                  }
                  span {
                    display: block !important;
                    color: #d61e37;
                    font-weight: 700;
                    font-size: 13px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
