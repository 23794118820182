.image-upload {
  .submit-image-btn {
    width: 104px;
  }
  .img-url-input {
    min-width: 200px;
    width: 100%;
    margin-bottom: 15px;
    padding: 10px 20px;
    border: $input-field-border;
  }

  .uploaded-result {
    background-color: $gray-bg;
    border-radius: 4px;
    // box-shadow: 0 0 4px black;

    &-img {
      height: 100px;
      width: 100px;
      padding: 15px;
      margin-right: 15px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        background: $gray-bg;
        width: 100%;
      }
    }

    &-txt {
      font-size: 0.8rem;
      width: 100%;
      overflow: auto;
      &::-webkit-scrollbar {
        height: 3px;
      }
      p {
        font-size: 14px;
      }
      p,
      small {
        white-space: nowrap;
      }
      .copy-icon-wraper {
        svg {
          margin-right: 10px !important;
          path {
            fill: #3531317a;
          }
        }
      }
    }
  }
}
