.show-details-inner-content {
  .rider-image-content {
    padding-bottom: 15px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    .rider-banner {
      position: relative;
      // z-index: -122;
      display: flex;
      flex-direction: column;
      align-items: center;
      > img {
        min-width: 100%;
        background-color: #dddddd40;
      }
      .rider-logo {
        max-width: 400px;
        max-height: 280px;
        overflow: hidden;
        // width: 100%;
        // position: absolute;
        bottom: 59px;
        // left: 50%;
        // transform: translateX(-50%);
        padding: 5px;
        // border: 6px solid #feeeed;
        // border-radius: 50%;
        // box-shadow: 0 3px 4px rgba(0, 0, 0, 0.5);
        text-align: center;
        // margin: auto;
        // display: block;
        // z-index: 9099999;
        padding: 30px;
        img {
          // border-radius: 50%;
          // height: 80px;
          // width: 80px;
          object-fit: cover;
        }
      }
      .rider-image-content-body {
        .body {
          // margin-top: 32px;
          text-align: center;
          h4 {
            margin-bottom: 2px;
            svg {
              margin-left: 6px;
              margin-left: 6px;
              z-index: 0;
              background: white;
              border-radius: 50%;
              padding: 2px;
              margin-top: -10px;
            }
          }
        }
      }
    }
  }
}
.rider-information-tab-menu {
  margin-top: 20px;
  .nav-item {
    a {
      font-size: 13px;
      font-weight: 700;
      color: #3a494e;
      position: relative;
      padding: 10px;
      svg {
        margin-right: 20px;
        margin-left: 20px;
      }
      &.active {
        background-color: #feeeed;
        position: relative;
        color: $theme-color;
        &::before {
          top: 0;
          content: "";
          position: absolute;
          left: 0;
          height: 100%;
          border-left: 4px;
          width: 4px;
          background-color: $theme-color;
        }
      }
    }
  }
}

.rider-details-wrapper-content {
  display: block;
}

.rider-information-show-tab-wrapper {
  .inner-content {
    .single-content {
      .single-information-rider-wrapper {
        .single-information-rider-content {
          display: flex;
          justify-content: flex-start;
          .info-icon {
            margin-top: 10px;
            height: 40px;
            width: 40px;
            background: red;
            text-align: center;
            line-height: 32px;
            border-radius: 50%;
            color: #fff;
            flex-shrink: 0;
          }
          .info-body {
            width: 100%;
            margin-left: 10px;
            .inner-body {
              margin-top: 25px;
              .single {
                display: flex;
                justify-content: space-between;
                line-height: 30px;
                margin-bottom: 10px;
                .info-name {
                  color: gray;
                  span {
                  }
                }
                .info-desc {
                  width: 50%;
                  float: left;
                  text-align: left;
                  span {
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.product-details-wrapper-content {
  display: block;
  .show-details-inner-content {
    .product-image-content {
      padding-bottom: 15px;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      .product-banner {
        position: relative;
        // z-index: -122;
        display: flex;
        flex-direction: column;
        align-items: center;
        > img {
          min-width: 100%;
          background-color: #dddddd40;
        }
        .product-logo {
          max-width: 400px;
          max-height: 280px;
          overflow: hidden;
          // width: 100%;
          // position: absolute;
          bottom: 59px;
          // left: 50%;
          // transform: translateX(-50%);
          padding: 5px;
          // border: 6px solid #feeeed;
          // border-radius: 50%;
          // box-shadow: 0 3px 4px rgba(0, 0, 0, 0.5);
          text-align: center;
          // margin: auto;
          // display: block;
          // z-index: 9099999;
          padding: 30px;
          img {
            // border-radius: 50%;
            // height: 80px;
            // width: 80px;
            object-fit: cover;
          }
        }
        .product-image-content-body {
          .body {
            // margin-top: 32px;
            text-align: center;
            h4 {
              margin-bottom: 2px;
              svg {
                margin-left: 6px;
                margin-left: 6px;
                z-index: 100000000000000;
                background: white;
                border-radius: 50%;
                padding: 2px;
                margin-top: -10px;
              }
            }
          }
        }
      }
    }
  }
  .product-information-tab-menu {
    margin-top: 20px;
    .nav-item {
      a {
        font-size: 13px;
        font-weight: 700;
        color: #3a494e;
        position: relative;
        padding: 10px;
        svg {
          margin-right: 20px;
          margin-left: 20px;
        }
        &.active {
          background-color: #feeeed;
          position: relative;
          color: $theme-color;
          &::before {
            top: 0;
            content: "";
            position: absolute;
            left: 0;
            height: 100%;
            border-left: 4px;
            width: 4px;
            background-color: $theme-color;
          }
        }
      }
    }
  }
}

.product-information-show-tab-wrapper {
  height: 100%;
  .inner-content {
    .single-content {
      .tab-content {
        .single-information-product-wrapper {
          .single-information-product-content {
            display: flex;
            justify-content: flex-start;
            .info-icon {
              margin-top: 10px;
              height: 40px;
              width: 40px;
              background: red;
              text-align: center;
              line-height: 32px;
              border-radius: 50%;
              color: #fff;
              flex-shrink: 0;
              font-size: 24px;
              font-weight: 600;
            }
            .info-body {
              width: 100%;
              margin-left: 20px;
              .inner-body {
                margin-top: 25px;
                overflow-y: auto;
                overflow-x: hidden;
                height: calc(100vh - 220px);
                .single {
                  display: flex;
                  justify-content: space-between;
                  line-height: 30px;
                  margin-bottom: 10px;
                  &.address-area {
                    span {
                      &:nth-child(2) {
                        width: 60%;
                        float: right;
                        text-align: right;
                      }
                    }
                  }
                }
                .single-inline {
                  .description {
                    h1 {
                      font: 1.5em sans-serif;
                      font-weight: 500;
                      margin-top: 2rem;
                    }
                    h2 {
                      font: 1rem sans-serif;
                      font-weight: 500;
                      margin-top: 2rem;
                    }
                    p {
                      font-size: 12px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.product_details {
  background: #fff;
  .info-body {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 280px);
  }
}

.product-add {
  .info-body {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 220px);
  }
}

.product-information-area {
  .product-details-wrapper-content {
    display: block;
    .show-details-inner-content {
      .product-image-content {
        padding-bottom: 15px;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        .product-banner {
          position: relative;
          // z-index: -122;
          display: flex;
          flex-direction: column;
          align-items: center;
          > img {
            min-width: 100%;
            background-color: #dddddd40;
          }
          .product-logo {
            max-width: 400px;
            max-height: 280px;
            overflow: hidden;
            // width: 100%;
            // position: absolute;
            bottom: 59px;
            // left: 50%;
            // transform: translateX(-50%);
            padding: 5px;
            // border: 6px solid #feeeed;
            // border-radius: 50%;
            // box-shadow: 0 3px 4px rgba(0, 0, 0, 0.5);
            text-align: center;
            // margin: auto;
            // display: block;
            // z-index: 9099999;
            padding: 30px;
            img {
              // border-radius: 50%;
              // height: 80px;
              // width: 80px;
              object-fit: cover;
            }
          }
          .product-image-content-body {
            .body {
              // margin-top: 32px;
              text-align: center;
              h4 {
                margin-bottom: 2px;
                svg {
                  margin-left: 6px;
                  margin-left: 6px;
                  z-index: 100000000000000;
                  background: white;
                  border-radius: 50%;
                  padding: 2px;
                  margin-top: -10px;
                }
              }
            }
          }
        }
      }
    }
    .product-information-tab-menu {
      margin-top: 20px;
      .nav-item {
        a {
          font-size: 13px;
          font-weight: 700;
          color: #3a494e;
          position: relative;
          padding: 10px;
          svg {
            margin-right: 20px;
            margin-left: 20px;
          }
          &.active {
            background-color: #feeeed;
            position: relative;
            color: $theme-color;
            &::before {
              top: 0;
              content: "";
              position: absolute;
              left: 0;
              height: 100%;
              border-left: 4px;
              width: 4px;
              background-color: $theme-color;
            }
          }
        }
      }
    }
  }

  .product-information-show-tab-wrapper {
    height: 100%;
    .inner-content {
      .single-content {
        .tab-content {
          .single-information-product-wrapper {
            .single-information-product-content {
              display: flex;
              justify-content: flex-start;
              .info-icon {
                margin-top: 10px;
                height: 40px;
                width: 40px;
                background: red;
                text-align: center;
                line-height: 32px;
                border-radius: 50%;
                color: #fff;
                flex-shrink: 0;
                font-size: 24px;
                font-weight: 600;
              }
              .info-body {
                width: 100%;
                margin-left: 20px;
                .inner-body {
                  margin-top: 25px;
                  overflow-y: auto;
                  overflow-x: hidden;
                  height: calc(100vh - 220px);
                  .single {
                    display: flex;
                    justify-content: space-between;
                    line-height: 30px;
                    margin-bottom: 10px;
                    &.address-area {
                      span {
                        &:nth-child(2) {
                          width: 60%;
                          float: right;
                          text-align: right;
                        }
                      }
                    }
                  }
                  .single-inline {
                    .description {
                      h1 {
                        font: 1.5em sans-serif;
                        font-weight: 500;
                        margin-top: 2rem;
                      }
                      h2 {
                        font: 1rem sans-serif;
                        font-weight: 500;
                        margin-top: 2rem;
                      }
                      p {
                        font-size: 12px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.product_details {
  background: #fff;
  .info-body {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 280px);
  }
}

.product-add {
  .info-body {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 220px);
  }
}
