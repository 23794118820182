.rider-list-item-area {
  .rider-list-items-inner-content {
    .single-rider-wrapper {
      display: flex;
      justify-content: flex-start;
      border-bottom: 0.5px solid #eceaea;
      padding: 15px 10px;
      .single-rider-heading,
      .single-rider {
        width: 100%;
        text-align: left;
        &.major-col {
          flex: 4 1 100%;
        }
        span {
          font-size: 13px;
          font-weight: 700;
          color: #777171;
        }
      }
    }
  }
  .rider-table-area {
    height: calc(100vh - 300px);
    overflow-y: scroll;
    .single-rider-wrapper {
      display: flex;
      justify-content: flex-start;
      padding-bottom: 10px;
      border-bottom: 0.5px solid #eceaea;
      padding-top: 10px;
      align-items: center;
      transition: all 0.3s linear;
      padding-left: 15px;
      padding-right: 15px;
      cursor: pointer;
      &:hover {
        background-color: #feeeed;
      }
      .single-rider {
        width: 100%;
        // display: flex;
        // align-items: center;
        // text-align: right;
        // padding-right: 6px;
        &.major-col {
          // flex: 2 1 80%;
          flex: 2 1 100%;
        }
        .product-img {
          margin-right: 15px;
          height: 32px;
          width: 32px;
          border-radius: 50%;
          flex-shrink: 0;
          img {
            height: 100%;
            width: 100%;
            border-radius: 50%;
          }
        }
      }
      .last-column{
        display: flex;
      }
      @media screen and (max-width: 1299px) {
        .last-column {
            display: flex;
            flex-direction: column;
            width: 100%;
           
        }
      }
    }
  }
}
