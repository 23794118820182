.shops-top--search-area
  .ant-select.ant-select-auto-complete.ant-select-single.ant-select-show-search {
  min-width: 225px;
  // max-width: 400px;
}

.seller-list {
  .name-search {
    min-width: 300px;
    .ant-input-group {
      // width: 100% !important;
    }
  }
  .status-search {
    input {
      min-width: 200px;
      margin-right: 15px;
    }
  }
}

.seller-list {
  .name-search {
    min-width: 300px;
    .ant-input-group {
      width: calc(100% - 50px) !important;
    }
  }
  .status-search {
    input {
      min-width: 200px;
      margin-right: 15px;
    }
  }
}

.shop-banner {
  position: relative;
  height: 10rem;
  // max-height: 10rem;
  // min-height: 10rem;
  overflow: hidden;
  background: #f7f7f7;
  border-radius: 5px;
}
.shop-image-content {
  position: relative;
  text-align: center;
  min-height: 8rem;

  .shop-logo {
    position: absolute;
    top: -4.3rem;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    margin: auto;
    border-radius: 50%;
    border: 0.5rem solid #f7f7f7;
    box-shadow: 1px -0px 10px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    padding: 0 !important;
    background: #fff;

    img {
      width: 100px;
      height: 100px;
      display: block;
      margin: 0 auto !important;
      padding: 0 !important;
    }
  }

  .body {
    position: relative;
    padding-top: 3rem;
    h4 {
      padding-top: 10px;
      font-size: 1rem;
    }
  }
}
.details-area {
  overflow: auto;
  height: calc(100vh - 240px);

  &.neighbourhood {
    height: calc(100vh - 180px);
  }
}
.order_details_area {
  overflow: auto;
  height: calc(100vh - 150px);
}
