.editor-form-wrapper {
  label {
    color: $heading-color;
    font-size: 14px;
    line-height: 19px;
    font-weight: $bold;
    padding-bottom: 0;
  }
  .editorClassName.rdw-editor-main {
      height: 200px;
      border: 1px solid #dddddd4d;
      border-radius: 2px;
      padding: 0px 10px;
  }
}
.rdw-editor-main {
  height: 150px !important;
  border: 1px solid #ddd;
  padding: 4px 10px;
}
